import { Add } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import { ColDef, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import {
  BankAccountConstants,
  IBankAccount,
  IBaseStore,
  isInProgress,
  LoadingIndicator,
  SectionHeader,
  useGridExtensions,
} from "common";
import React, { useMemo, useRef } from "react";
import { useSelector } from "react-redux";

import styles from "./BankAccountsCard.module.scss";

interface BankAccountsCardProps {
  noRowsOverlayComponent: () => JSX.Element;
}

export const BankAccountsCard = ({
  noRowsOverlayComponent,
}: BankAccountsCardProps) => {
  const gridRef = useRef<AgGridReact<IBankAccount>>(null);
  const { setHeaderHeight, resizeColumns, onGridReady } = useGridExtensions();

  const bankAccountsLoadStatus = useSelector(
    (state: IBaseStore) => state.bankAccounts.bankAccountsLoadStatus
  );

  const bankAccountsForClient = useSelector(
    (state: IBaseStore) => state.bankAccounts.serverData
  );

  const defaultColDef: ColDef<IBankAccount> = useMemo(
    () => ({
      resizable: true,
      filter: false,
      minWidth: 110,
      menuTabs: [],
      sortable: false,
      lockPinned: true,
      suppressHeaderMenuButton: true,
    }),
    []
  );

  type SafeColDef<T> = Omit<ColDef<T>, "field"> & { field?: keyof T };
  const colDefs: SafeColDef<IBankAccount>[] = useMemo(() => {
    return [
      {
        field: "accountHolderName",
        headerName: BankAccountConstants.ACCOUNT_HOLDER,
        minWidth: 200,
        resizable: true,
        valueFormatter: (params: { value: string }) => {
          return params.value;
        },
      },
      {
        field: "bankName",
        headerName: BankAccountConstants.BANK_NAME,
        minWidth: 200,
        resizable: true,
        valueFormatter: (params: { value: string }) => {
          return params.value;
        },
      },
      {
        field: "accountNumber",
        headerName: BankAccountConstants.ACCOUNT_ENDING_IN,
        minWidth: 200,
        resizable: true,
        valueFormatter: (params: { value: string }) => {
          return params.value;
        },
      },
      {
        field: "accountType",
        headerName: BankAccountConstants.TYPE,
        minWidth: 200,
        resizable: true,
        valueFormatter: (params: { value: string }) => {
          return params.value;
        },
      },
    ];
  }, []);

  const rowData: IBankAccount[] = useMemo(() => {
    const bankAccounts: IBankAccount[] = [];
    if (bankAccountsForClient) {
      if (bankAccountsForClient.bankAccounts.length > 0) {
        bankAccounts.push(
          ...bankAccountsForClient.bankAccounts.map(
            (item) => item.bankAccount.main
          )
        );
      }
      if (bankAccountsForClient.pendingBankAccounts.length > 0) {
        bankAccounts.push(
          ...bankAccountsForClient.pendingBankAccounts.map((item) => item.main)
        );
      }
    }
    return bankAccounts;
  }, [bankAccountsForClient]);

  return (
    <Stack className={styles.cardContainer}>
      <div className={styles.cardHeader}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Typography variant="h1">
            {SectionHeader.BANK_ACCOUNTS_TITLE}
          </Typography>
          <Button endIcon={<Add />}>{SectionHeader.NEW_BANK_ACCOUNT}</Button>
        </Stack>
        <Typography variant="body1">
          {SectionHeader.BANK_ACCOUNTS_DESCRIPTION}
        </Typography>
      </div>
      <Stack className="ag-theme-alpine" id={styles.dataGrid}>
        <AgGridReact<IBankAccount>
          loading={isInProgress(bankAccountsLoadStatus)}
          ref={gridRef}
          rowData={rowData}
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          columnDefs={colDefs}
          onFirstDataRendered={setHeaderHeight}
          onColumnResized={setHeaderHeight}
          cacheQuickFilter={true}
          suppressAggFuncInHeader={true}
          suppressContextMenu={true}
          suppressCellFocus={true}
          onRowDataUpdated={resizeColumns}
          onGridSizeChanged={resizeColumns}
          onDisplayedColumnsChanged={resizeColumns}
          onModelUpdated={resizeColumns}
          suppressMenuHide={true}
          enableCellTextSelection={true}
          onGridReady={(params: GridReadyEvent) => {
            onGridReady(params);
          }}
          noRowsOverlayComponent={noRowsOverlayComponent}
          scrollbarWidth={10}
          suppressDragLeaveHidesColumns={true}
          suppressMovableColumns={true}
          tooltipShowDelay={0}
          loadingOverlayComponent={LoadingIndicator}
        />
      </Stack>
    </Stack>
  );
};
