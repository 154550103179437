import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";

import { CookieNotificationConstants } from "./constants";

interface CookieNotificationProps {
  open: boolean;
  onAcceptCookies: () => void;
  onDeclineCookies: () => void;
}

export const CookieNotification = (props: CookieNotificationProps) => {
  return (
    <Dialog open={props.open}>
      <DialogTitle variant="h3">
        {CookieNotificationConstants.TITLE}
      </DialogTitle>
      <DialogContent>{CookieNotificationConstants.DISCLAIMER}</DialogContent>
      <DialogActions>
        <Button onClick={props.onDeclineCookies} color="secondary">
          {CookieNotificationConstants.DECLINE_BUTTON_TEXT}
        </Button>
        <Button onClick={props.onAcceptCookies}>
          {CookieNotificationConstants.ACCEPT_BUTTON_TEXT}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
