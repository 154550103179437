export const LoginConstants = {
  Error: {
    EMPTY_USERNAME: "Please enter your username or email to continue.",
  },
  INPUT_PLACEHOLDER: "Username or email",
  NEXT_BUTTON_TEXT: "Next",
  REMEMBER_ME_LABEL: "Remember Me",
  TITLE: "Welcome to Blackstone",
  USERNAME_COOKIE: "username",
};
