import {
  FailedToLoadError,
  IBankAccountPermittedClient,
  IBaseStore,
  isSomething,
  isUnsuccessful,
  reqBankAccountForClient,
  setActiveBankAccountClient,
  some,
  useFetchDatasetIfIdDefined,
} from "common";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ClientDropdown } from "../../common/components/ClientDropdown/ClientDropdown";
import { InvestorUIStore } from "../../redux/store";
import { BankAccountsCard } from "./BankAccountsCard/BankAccountsCard";
import styles from "./BankAcounts.module.scss";
import { NoBankAccountsMessage } from "./NoBankAccountsMessage/NoBankAccountsMessage";

export const BankAccounts = () => {
  const dispatch = useDispatch();

  const activeBankAccountsClient = useSelector(
    (state: IBaseStore) => state.bankAccounts.activeBankAccountsClient
  );

  const bankAccountsLoadStatus = useSelector(
    (state: IBaseStore) => state.bankAccounts.bankAccountsLoadStatus
  );

  const retryFunction = useFetchDatasetIfIdDefined(
    reqBankAccountForClient,
    activeBankAccountsClient,
    bankAccountsLoadStatus
  );

  const overlayComponent = useMemo(() => {
    return isUnsuccessful(bankAccountsLoadStatus)
      ? () => <FailedToLoadError retryRequest={retryFunction} />
      : NoBankAccountsMessage;
  }, [bankAccountsLoadStatus, retryFunction]);

  const {
    bankAccountsEntitlements: { permittedBankAccountClients },
  } = useSelector((store: InvestorUIStore) => store.entitlements);

  useEffect(() => {
    if (!isSomething(activeBankAccountsClient) && permittedBankAccountClients) {
      dispatch(
        setActiveBankAccountClient(some(permittedBankAccountClients[0]))
      );
      dispatch(reqBankAccountForClient(permittedBankAccountClients[0]));
    }
  });

  const onClientChange = (client: IBankAccountPermittedClient) => {
    dispatch(setActiveBankAccountClient(some(client)));
    dispatch(reqBankAccountForClient(client));
  };

  return (
    <div className={styles.page}>
      <div className={styles.pageHeader}>
        <ClientDropdown
          activeClient={activeBankAccountsClient}
          permittedClients={permittedBankAccountClients}
          onChange={onClientChange}
        />
      </div>
      <div className={styles.pageContent}>
        <BankAccountsCard noRowsOverlayComponent={overlayComponent} />
      </div>
    </div>
  );
};
