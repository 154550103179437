import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

import { FileDownloadOutlined } from "@mui/icons-material";
import {
  Badge,
  Button,
  Fab,
  SelectChangeEvent,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { CellClickedEvent, GridReadyEvent } from "ag-grid-community";
import { ColDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import {
  BreakpointConstants,
  buildAndDownloadZipFile,
  closeAlert,
  ColumnStates,
  createDocumentRequest,
  dateFormatterMMMDDYYYYOrNA,
  DEFAULT_COLUMN_DEF,
  DefaultColumnDefinitions,
  DocumentGridColumnKeys,
  DocumentRequest,
  DocumentsLabel,
  DownloadStatusCellRenderer,
  DownloadStatusIcon,
  ErrorStringConstants,
  getBulkDocumentBlob,
  getDocDownloadTimeZoneQueryParameters,
  GridHeaderWithFilterAndSort,
  hasFiltersSelected,
  IDocument,
  IDocumentsClient,
  IDocumentSortOptions,
  isInProgress,
  isSomething,
  isValidPageSize,
  LoadingIndicator,
  markDocumentsAsDownloaded,
  nothing,
  openAlert,
  Optional,
  PageSize,
  PaginationBar,
  reqDocuments,
  reqDocumentsForSearch,
  SearchBar,
  some,
  toCommaDelineatedString,
  toDocCatTypeNameString,
  toDocTypeNameString,
  toEntityCommaDelineatedString,
  toLocaleStringOrNA,
  TooltipCellRenderer,
  toPartnershipEINCommaDelineatedString,
  updateDocumentSearchTerm,
  updateDocumentSortOptions,
  useDownloadSingleDocument,
  useFetchDatasetIfIdDefined,
  useGridExtensions,
} from "common";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { InvestorUIStore } from "../../../../redux/store";
import { DocumentsGridFilter } from "../DocumentsGridFilter/DocumentsGridFilter";
import { ManageColumns } from "../ManageColumns/ManageColumns";
import { NoDocumentsAvailableError } from "../NoDocumentsAvailableError/NoDocumentsAvailableError";
import { NoDocumentsWithSearchOrFilterError } from "../NoDocumentsAvailableError/NoDocumentsWithSearchOrFilterError";
import { DocumentGridCheckboxCell } from "./Checkbox/DocumentGridCheckboxCell";
import { DocumentGridHeaderCheckbox } from "./Checkbox/DocumentGridHeaderCheckbox";
import {
  DocumentNameGridCellRenderer,
  IDocNameCellRendererParams,
} from "./DocumentNameGridCellRenderer/DocumentNameGridCellRenderer";
import styles from "./DocumentsGrid.module.scss";

export const DocumentsGrid = () => {
  const gridRef = useRef<AgGridReact<IDocument>>(null);
  const [gridReady, setGridReady] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { setHeaderHeight, resizeColumns, onGridReady } = useGridExtensions();

  const MAX_BULK_DOWNLOAD_DOCUMENTS = 250;

  const {
    activeDocumentClient,
    columnStates,
    documentsForGrid,
    documentLoadStatus,
    selectedDocuments,
    page,
    pageSize,
    searchTerm,
    sortOptions,
  } = useSelector((store: InvestorUIStore) => store.documentSettings);

  const selectedDocumentCount = useMemo(
    () => Object.keys(selectedDocuments).length,
    [selectedDocuments]
  );

  const [hasDocuments, setHasDocuments] = useState<boolean>(true);

  const hasActiveFilters = useMemo(() => {
    return hasFiltersSelected(columnStates);
  }, [columnStates]);

  const timeZoneQueryParams = useMemo(
    () => getDocDownloadTimeZoneQueryParameters().toString(),
    []
  );

  /*
  callback function that requests documents and sets the user's page/page size properly
  */
  const fetchDocuments = useCallback(
    (
      actionToDispatch: ActionCreatorWithPayload<DocumentRequest, string>,
      page: number,
      pageSize: PageSize,
      columnStates: ColumnStates,
      searchTerm: Optional<string>,
      sort: Optional<IDocumentSortOptions>,
      activeDocumentClient: Optional<IDocumentsClient>
    ) => {
      const request = createDocumentRequest(
        page,
        pageSize,
        columnStates,
        searchTerm,
        sort,
        activeDocumentClient
      );
      if (isSomething(request)) {
        dispatch(actionToDispatch(request.value));
      }
    },
    [dispatch]
  );

  useFetchDatasetIfIdDefined(
    reqDocuments,
    createDocumentRequest(
      page,
      pageSize,
      columnStates,
      searchTerm,
      sortOptions,
      activeDocumentClient
    ),
    documentLoadStatus
  );

  const onChangePageSize = (event: SelectChangeEvent<PageSize>) => {
    const { value } = event.target;
    if (isValidPageSize(value)) {
      fetchDocuments(
        reqDocuments,
        1, // set back to first page on change of page size
        value,
        columnStates,
        searchTerm,
        sortOptions,
        activeDocumentClient
      );
    }
  };

  const isMobile = useMediaQuery(
    `(max-width:${BreakpointConstants.EXTRA_SMALL_MAX_WIDTH}px)`
  );

  const handleSort = useCallback(
    (newSortOptions: Optional<IDocumentSortOptions>) => {
      dispatch(updateDocumentSortOptions(newSortOptions));
      fetchDocuments(
        reqDocuments,
        page,
        pageSize,
        columnStates,
        searchTerm,
        newSortOptions,
        activeDocumentClient
      );
    },
    [
      activeDocumentClient,
      columnStates,
      dispatch,
      fetchDocuments,
      page,
      pageSize,
      searchTerm,
    ]
  );

  const { downloadSingleDocument } = useDownloadSingleDocument();

  const handleBulkDownload = async () => {
    dispatch(
      openAlert({
        message: DocumentsLabel.PREPARING_DOCUMENTS,
        severity: "info",
        hideDuration: null, // Keep alert open until we or the user close it!
      })
    );

    const fileBlob = await getBulkDocumentBlob(Object.keys(selectedDocuments));

    if (fileBlob === undefined) {
      // undefined if a non-200 result is returned from endpoint
      // sets download toast to display error alert
      setErrorToast();
    } else {
      // received file from endpoint - downloads to browser and sets download success toast message
      // update document download date to reflect new download without triggered re-fetch from the API,
      // which causes problems on Safari because Safari suspends JS execution on downloading a document
      dispatch(
        markDocumentsAsDownloaded(
          Object.keys(selectedDocuments).map((id) => Number(id))
        )
      );
      dispatch(closeAlert());
      buildAndDownloadZipFile(fileBlob, "BXWealth Documents_");
    }
  };

  const handleDownloadClick = async () => {
    if (selectedDocumentCount === 1) {
      // if only one document selected, do regular single file download (do not zip)
      const selectedDocumentOId: string = Object.keys(selectedDocuments)[0];
      const selectedDocument: IDocument = documentsForGrid.documents.filter(
        (document: IDocument) =>
          document.documentOId.toString() === selectedDocumentOId
      )[0];
      await downloadSingleDocument(selectedDocument.documentOId);
    } else {
      // if more than 1 document selected, zip the download
      await handleBulkDownload();
    }
  };

  const handleCellClick = async (event: CellClickedEvent<IDocument>) => {
    const document: IDocument | undefined = event.data;
    if (document != undefined && event.column.getColDef().pinned != "left") {
      // only want to download docs when clicking on non-pinned columns in the row
      downloadSingleDocument(document.documentOId);
    }
  };

  const setErrorToast = useCallback(
    () =>
      dispatch(
        openAlert({
          severity: "error",
          message: ErrorStringConstants.DOWNLOAD_FAILED,
        })
      ),
    [dispatch]
  );

  useEffect(() => {
    if (gridRef.current !== null && gridReady) {
      if (isInProgress(documentLoadStatus)) {
        gridRef.current.api.showLoadingOverlay();
        setHasDocuments(true);
      } else {
        if (documentsForGrid.documentsCount === 0) {
          // if user has filters or search applied, they have documents
          setHasDocuments(hasActiveFilters || isSomething(searchTerm));
          gridRef.current.api.setGridOption("suppressNoRowsOverlay", false);
          // need timeout so turning off suppress rows takes effect
          setTimeout(() => {
            if (gridRef.current !== null) {
              gridRef.current.api.showNoRowsOverlay();
            }
          }, 0);
        } else {
          gridRef.current.api.hideOverlay();
          setHasDocuments(true);
        }
      }
    }
  }, [
    documentLoadStatus,
    documentsForGrid.documentsCount,
    gridReady,
    hasActiveFilters,
    searchTerm,
  ]);

  const NoRowsOverlayComponent = useMemo(() => {
    if (hasActiveFilters || isSomething(searchTerm)) {
      return NoDocumentsWithSearchOrFilterError;
    } else {
      return NoDocumentsAvailableError;
    }
  }, [hasActiveFilters, searchTerm]);

  /*
  helper function to hide the tooltip of the download status icon
  */
  const hideDownloadStatusTooltip = () => {
    const el = document.getElementById(DocumentsLabel.DOWNLOAD_STATUS_ICON_ID);
    if (el) {
      el.style.display = "none";
    }
  };

  // actually type the field property to avoid garbage keys,
  type SafeColDef<T> = Omit<ColDef<T>, "field"> & { field?: keyof T };
  const documentsColumnDefs: SafeColDef<IDocument>[] = useMemo(
    () => [
      {
        headerComponent: DocumentGridHeaderCheckbox,
        cellRenderer: DocumentGridCheckboxCell,
        minWidth: 60,
        maxWidth: 60,
        cellClass: styles.checkboxSelectionCell,
        lockPosition: "left",
        pinned: "left",
        resizable: false,
      },
      {
        field: DocumentGridColumnKeys.DOWNLOADED_DATE,
        headerComponent: DownloadStatusIcon,
        headerComponentParams: {
          showFilterIcon:
            DefaultColumnDefinitions.downloadedDate.filter.filterActiveCheck(
              columnStates.downloadedDate.filter.values
            ),
        },
        maxWidth:
          DefaultColumnDefinitions.downloadedDate.filter.filterActiveCheck(
            columnStates.downloadedDate.filter.values
          )
            ? 80
            : 65,
        minWidth: 65,
        cellRenderer: DownloadStatusCellRenderer,
        cellClass: styles.downloadStatusCell,
        lockPosition: "left",
        pinned: "left",
        resizable: false,
      },
      {
        field: DocumentGridColumnKeys.DOCUMENT_NAME,
        headerName: DocumentsLabel.DOCUMENT_NAME,
        headerComponent: GridHeaderWithFilterAndSort,
        headerComponentParams: {
          showFilterIcon: false, // document name is not a filterable option
          fieldToSortBy: columnStates.documentName.sortField,
          handleSort: handleSort,
          currentSortOptions: sortOptions,
        },
        valueFormatter: toLocaleStringOrNA,
        minWidth: 490,
        cellRenderer: DocumentNameGridCellRenderer,
        cellRendererParams: {
          timeZoneQueryParams,
        } as IDocNameCellRendererParams,
      },
      {
        field: DocumentGridColumnKeys.DOCUMENT_TYPE,
        headerName: DocumentsLabel.DOCUMENT_TYPE,
        headerComponent: GridHeaderWithFilterAndSort,
        headerComponentParams: {
          showFilterIcon:
            DefaultColumnDefinitions.documentType.filter.filterActiveCheck(
              columnStates.documentType.filter.values
            ),
          fieldToSortBy: columnStates.documentType.sortField,
          handleSort: handleSort,
          currentSortOptions: sortOptions,
        },
        valueFormatter: toDocTypeNameString,
        minWidth: 200,
      },
      {
        field: DocumentGridColumnKeys.DOCUMENT_TYPE,
        headerName: DocumentsLabel.CATEGORY,
        headerComponent: GridHeaderWithFilterAndSort,
        headerComponentParams: {
          showFilterIcon:
            DefaultColumnDefinitions.documentTypeCategory.filter.filterActiveCheck(
              columnStates.documentTypeCategory.filter.values
            ),
        },
        valueFormatter: toDocCatTypeNameString,
        hide: !columnStates.documentTypeCategory.show,
        minWidth: 200,
      },
      {
        field: DocumentGridColumnKeys.PERIOD,
        headerName: DocumentsLabel.PERIOD,
        headerComponent: GridHeaderWithFilterAndSort,
        headerComponentParams: {
          showFilterIcon:
            DefaultColumnDefinitions.period.filter.filterActiveCheck(
              columnStates.period.filter.values
            ),
          handleSort: handleSort,
          fieldToSortBy: columnStates.period.sortField,
          currentSortOptions: sortOptions,
        },
        valueFormatter: toLocaleStringOrNA,
        minWidth: 200,
        maxWidth: 300,
        hide: !columnStates.period?.show,
      },
      {
        field: DocumentGridColumnKeys.FUNDS,
        headerName: DocumentsLabel.FUND,
        headerComponent: GridHeaderWithFilterAndSort,
        headerComponentParams: {
          showFilterIcon:
            DefaultColumnDefinitions.funds.filter.filterActiveCheck(
              columnStates.funds.filter.values
            ),
        },
        valueFormatter: toEntityCommaDelineatedString,
        cellRenderer: TooltipCellRenderer,
        hide: !columnStates.funds.show,
        minWidth: 200,
      },
      {
        field: DocumentGridColumnKeys.PUBLISHED_DATE,
        headerName: DocumentsLabel.PUBLISHED,
        headerComponent: GridHeaderWithFilterAndSort,
        headerComponentParams: {
          showFilterIcon:
            DefaultColumnDefinitions.publishedDate.filter.filterActiveCheck(
              columnStates.publishedDate.filter.values
            ),
          handleSort: handleSort,
          fieldToSortBy: columnStates.publishedDate.sortField,
          currentSortOptions: sortOptions,
        },
        valueFormatter: dateFormatterMMMDDYYYYOrNA,
        minWidth: 200,
        maxWidth: 300,
        hide: !columnStates.publishedDate.show,
      },
      {
        field: DocumentGridColumnKeys.INVESTMENT_VEHICLES,
        headerName: DocumentsLabel.IV,
        headerComponent: GridHeaderWithFilterAndSort,
        headerComponentParams: {
          showFilterIcon:
            DefaultColumnDefinitions.investmentVehicles.filter.filterActiveCheck(
              columnStates.investmentVehicles.filter.values
            ),
        },
        valueFormatter: toEntityCommaDelineatedString,
        cellRenderer: TooltipCellRenderer,
        hide: !columnStates.investmentVehicles.show,
        minWidth: 200,
      },
      {
        field: DocumentGridColumnKeys.PARTNERSHIPS,
        headerName: DocumentsLabel.PARTNERSHIP,
        headerComponent: GridHeaderWithFilterAndSort,
        headerComponentParams: {
          showFilterIcon:
            DefaultColumnDefinitions.partnerships.filter.filterActiveCheck(
              columnStates.partnerships.filter.values
            ),
        },
        valueFormatter: toPartnershipEINCommaDelineatedString,
        cellRenderer: TooltipCellRenderer,
        hide: !columnStates.partnerships.show,
        minWidth: 200,
      },
      {
        field: DocumentGridColumnKeys.TAX_DOCUMENT_STATUS,
        headerName: DocumentsLabel.EST_FINAL,
        headerComponent: GridHeaderWithFilterAndSort,
        headerComponentParams: {
          showFilterIcon:
            DefaultColumnDefinitions.taxDocumentStatus.filter.filterActiveCheck(
              columnStates.taxDocumentStatus.filter.values
            ),
        },
        valueFormatter: toLocaleStringOrNA,
        hide: !columnStates.taxDocumentStatus?.show,
        minWidth: 200,
      },
      {
        field: DocumentGridColumnKeys.JURISDICTIONS,
        headerName: DocumentsLabel.JURISDICTION,
        headerComponent: GridHeaderWithFilterAndSort,
        headerComponentParams: {
          showFilterIcon:
            DefaultColumnDefinitions.jurisdictions.filter.filterActiveCheck(
              columnStates.jurisdictions.filter.values
            ),
          handleSort: handleSort,
          fieldToSortBy: columnStates.jurisdictions.sortField,
          currentSortOptions: sortOptions,
        },
        valueFormatter: toCommaDelineatedString,
        hide: !columnStates.jurisdictions.show,
        minWidth: 200,
      },
      {
        field: DocumentGridColumnKeys.EFFECTIVE_DATE,
        headerName: DocumentsLabel.EFFECTIVE_DATE,
        headerComponent: GridHeaderWithFilterAndSort,
        headerComponentParams: {
          showFilterIcon:
            DefaultColumnDefinitions.effectiveDate.filter.filterActiveCheck(
              columnStates.effectiveDate.filter.values
            ),
          handleSort: handleSort,
          fieldToSortBy: columnStates.effectiveDate.sortField,
          currentSortOptions: sortOptions,
        },
        valueFormatter: dateFormatterMMMDDYYYYOrNA,
        hide: !columnStates.effectiveDate?.show,
        minWidth: 200,
        maxWidth: 300,
      },
    ],
    [columnStates, sortOptions, handleSort, timeZoneQueryParams]
  );

  const setColumnVisibility = (visibleCols: string[], hiddenCols: string[]) => {
    // use set timeout because ag grid api needs it to avoid weird race conditions
    setTimeout(() => {
      if (gridRef.current !== null) {
        gridRef.current.api.setColumnsVisible(visibleCols, true);
        gridRef.current.api.setColumnsVisible(hiddenCols, false);
      }
    }, 0);
  };

  if (selectedDocumentCount > MAX_BULK_DOWNLOAD_DOCUMENTS) {
    dispatch(
      openAlert({
        message: DocumentsLabel.MAX_DOWNLOAD_SIZE_WARNING,
        severity: "warning",
      })
    );
  }

  return (
    <Stack className={styles.container}>
      <Stack direction="row" className={styles.gridToolbar}>
        <SearchBar
          disabled={!hasDocuments}
          placeholder={DocumentsLabel.SEARCH(isMobile)}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const newSearchTerm =
              event.target.value != "" ? some(event.target.value) : nothing;
            dispatch(updateDocumentSearchTerm(newSearchTerm));
            // don't filter for search term if only 1-2 chars entered due to solr constraints
            if (
              event.target.value.length > 2 ||
              event.target.value.length == 0
            ) {
              fetchDocuments(
                reqDocumentsForSearch,
                1, // set page back to 1 on search
                pageSize,
                columnStates,
                newSearchTerm,
                sortOptions,
                activeDocumentClient
              );
            }
          }}
          // we want to retrieve results on user hitting enter key as well
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Enter") {
              fetchDocuments(
                reqDocumentsForSearch,
                1, // set page back to 1 on search
                pageSize,
                columnStates,
                searchTerm,
                sortOptions,
                activeDocumentClient
              );
            }
          }}
          value={isSomething(searchTerm) ? searchTerm.value : undefined}
          className={styles.searchBar}
        />
        <Stack direction="row" className={styles.gridOptions}>
          <DocumentsGridFilter disabled={!hasDocuments} />
          <ManageColumns
            disabled={!hasDocuments}
            setColumnVisibility={setColumnVisibility}
          />
          {!isMobile && (
            <Button
              disabled={
                selectedDocumentCount < 1 ||
                selectedDocumentCount > MAX_BULK_DOWNLOAD_DOCUMENTS
              }
              onClick={handleDownloadClick}
            >
              Download ({selectedDocumentCount})
            </Button>
          )}
        </Stack>
      </Stack>
      <Stack className="ag-theme-alpine" id={styles.dataGrid}>
        <AgGridReact<IDocument>
          ref={gridRef}
          rowData={documentsForGrid.documents}
          defaultColDef={DEFAULT_COLUMN_DEF}
          columnDefs={documentsColumnDefs}
          onFirstDataRendered={setHeaderHeight}
          onColumnResized={setHeaderHeight}
          cacheQuickFilter={true}
          suppressAggFuncInHeader={true}
          suppressContextMenu={true}
          suppressCellFocus={true}
          onRowDataUpdated={resizeColumns}
          onGridSizeChanged={resizeColumns}
          onDisplayedColumnsChanged={resizeColumns}
          onModelUpdated={resizeColumns}
          suppressMenuHide={true}
          enableCellTextSelection={true}
          onGridReady={(params: GridReadyEvent) => {
            setGridReady(true);
            onGridReady(params);
          }}
          loadingOverlayComponent={LoadingIndicator}
          noRowsOverlayComponent={NoRowsOverlayComponent}
          suppressNoRowsOverlay={true}
          scrollbarWidth={10}
          onCellClicked={handleCellClick}
          suppressDragLeaveHidesColumns={true}
          onBodyScroll={hideDownloadStatusTooltip}
        />
        {selectedDocumentCount > 0 && isMobile && (
          <div className={styles.mobileDownloadWrapper}>
            <Badge badgeContent={selectedDocumentCount} max={999} color="info">
              <Fab
                onClick={handleDownloadClick}
                disabled={selectedDocumentCount > MAX_BULK_DOWNLOAD_DOCUMENTS}
              >
                <FileDownloadOutlined />
              </Fab>
            </Badge>
          </div>
        )}
      </Stack>
      <PaginationBar
        currentPage={page}
        onPageChange={(e, newPage) => {
          fetchDocuments(
            reqDocuments,
            newPage,
            pageSize,
            columnStates,
            searchTerm,
            sortOptions,
            activeDocumentClient
          );
        }}
        onPageSizeChange={onChangePageSize}
        pageSize={pageSize}
        rowsOnCurrentPage={documentsForGrid.documents.length}
        totalRows={documentsForGrid.documentsCount}
      />
    </Stack>
  );
};
